














import { Vue, Component } from 'vue-property-decorator'
import login from './login.vue'
import { State } from 'vuex-class'

@Component({
  components: {
    login
  }
})
export default class Login extends Vue {
    @State(state => state.system.systemTitle) systemTitle!: string;
}
