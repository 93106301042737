






















import { ElForm } from 'element-ui/types/form'
import { Vue, Component, Ref } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const system = namespace('system')
const user = namespace('user')
@Component
export default class login extends Vue {
  @system.Mutation('setToken') setToken!: (T: string) => void;
  @user.Mutation('setUserInfo') setUserInfo!: (T: string) => void;
  @Ref() readonly ruleForm!: ElForm;

  labelPosition = 'right';
  loading = false;
  formLabelAlign = {
    username: 'admin',
    password: 'Zzb123456'
  };

  rules = {
    username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
    password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
  };

  async login () {
    this.ruleForm.validate(async (valid) => {
      if (valid) {
        this.loading = true
        let res:any
        try {
          res = await this.$Post(this.$Api.login, this.formLabelAlign, {
            needAll: true
          })
          this.loading = false
        } catch (error) {
          this.loading = false
        }

        this.setToken((res as any).headers.authorization)
        this.setUserInfo(JSON.stringify(res.data.data))
        this.$router.push('/index')
      }
    })
  }
}
